/*
 * @Description: 配置
 * @Author: luocheng
 * @Date: 2022-06-20 09:45:43
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-07-12 10:23:30
 */

/**
 * @desc: 根据路由获取原始配置信息
 * @param {String} path 页面路由
 */

 export const getMenuOriginData = (path, arr) => {
  if (!path || !Array.isArray(arr)) return null;
  const currentApp = sessionStorage.getItem('currentApp');
  if (!currentApp) return null;
  let result = arr.find(ele => {
    return ele.menu_path === path
  });
  if (result) {
    return result;
  }
  for (let i = 0; i < arr.length; i++) {
    const { children_list = [] } = arr[i];
    result = getMenuOriginData(path, children_list);
    if (result) {
      return result;
    }
  }
  return null;
}

// 信息相关
export const infoList = [

  {
    icon: require('@/assets/images/second-screen/info-p1.png'),
    title: '参建各方',
    type: '1',
    path: '/all_parties_involved_in_the_construction?pageUUID=page62beb2a27c5b4',
    query: {},
    isScreen: false,
  },
  {
    icon: require('@/assets/images/second-screen/info-p2.png'),
    title: '总包架构',
    type: '2',
    path: '/general_contracting_structure?pageUUID=page62beb2898122b',
    query: {},
    isScreen: false,
  },
  {
    icon: require('@/assets/images/second-screen/info-p3.png'),
    title: '创新创优',
    type: '3',
    path: '/qc_innovation?pageUUID=page62b6d45f20e29',
    query: {},
    isScreen: false,
  },
  {
    icon: require('@/assets/images/second-screen/info-p4.png'),
    title: '规章制度',
    type: '4',
    path: '/rules_and_regulations_new?pageUUID=page62b7f920f00e2',
    query: {},
    isScreen: false,
  },
  {
    icon: require('@/assets/images/second-screen/info-p5.png'),
    title: '操作手册',
    type: '5',
    path: '/operation_manual?pageUUID=page62b8126965de3',
    query: {},
    isScreen: false,
  },
];