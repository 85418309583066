<!--
 * @Description: 常规页面配置
 * @Author: luocheng
 * @Date: 2021-08-17 16:59:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-11-23 17:05:52
-->
<template>
	<div class="normal"  :style="{zoom:zoom}">
		<ComponentBox
			v-for="element in componentList"
			:key="element.id"
			:element="element"
			:isPreview="true"
			@click.native.stop="onClickBox(element, 'click')"
			@mouseleave.native.stop="onClickBox(element, 'mouseleave')"
			@mouseenter.native.stop="onClickBox(element, 'mouseenter')"
		></ComponentBox>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { judgingEventLogic, getStatusVal } from '@/utils/tools';

export default {
	name: 'NormalPage',
	props: {
		customComponentData: {
			type: [Array, null],
			required: false,
			default: () => {
				return null;
			}
		}
	},
	data() {
		return {
			componentList: [],
      zoom:1
		};
	},
	computed: {
		...mapState([
			'componentData', // 图层列表
			'sContainer',
			'originComponentData', // 大屏容器组件的元组件
			'_PageCustomStatus',
			'_APPCustomStatus'
		])
	},
	created() {
		this.componentList = this.customComponentData;
    this.zoom = parseFloat(window.innerWidth/1920).toFixed(8)
    // console.log(scale);
    // console.log(window.devicePixelRatio);
    // if(scale>1){
    //    this.zoom = ((1/window.devicePixelRatio) * scale).toFixed(2)
    // }else{
    //   this.zoom = (1/window.devicePixelRatio).toFixed(2)  
    // }
    // this.zoom = scale
		// console.log(this.componentList, '----0000---', this.customComponentData)
	},
	methods: {
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(item, eventType) {
			if (
				!item.events ||
				!item.events[eventType] ||
				!Array.isArray(item.events[eventType].effects)
			) {
				return false
			}
			const click = item.events[eventType] || {};
			const { pattern, effects = [], specialEventList = []} = click;
			if (!pattern) {
				if (click.actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(click.stateMachine || []);
				}
				effects.forEach((ele) => {
					this.$store.commit('triggerEvents', {
						config: ele,
						element: item
					});
				});
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					}
					ele.effects.forEach(effect => {
						this.$store.commit('triggerEvents', {
							config: effect,
							element: item
						});
					})
				})
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.customComponentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			})
		},
	}
};
</script>

<style lang="less" scoped>
.normal {
	height: 100%;
	width: 100%;
	position: relative;
	background: #fff;
	margin: auto;
	transition: all 0.3s;
	overflow: hidden;
}
</style>